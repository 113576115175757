<template>
  <div id="userLayout">
    <div class="login_panle">

      <div class="login_panle_form">
        <div class="login_panle_form_title">
          <p class="login_panle_form_title_p">{{ this.title }}</p>
        </div>

        <el-form v-if="!needInit" ref="loginForm" :model="loginForm" :rules="rules" @keyup.enter="submitForm">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" :placeholder="this.t('dashboard.placeholderUsername')">
              <template #suffix>
                <span class="input-icon"> <el-icon> <user /> </el-icon> </span>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input v-model="loginForm.password" :type="lock === 'lock' ? 'password' : 'text'"
                      :placeholder="this.t('dashboard.placeholderPassword')">
              <template #suffix>
                <span class="input-icon">
                  <el-icon><component :is="lock" @click="changeLock" /></el-icon>
                </span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item style="position: relative" prop="captcha">
            <el-row :gutter="20">
            <el-col :span="14">
              <el-input
                v-model="loginForm.captcha"
                name="logVerify"
                :placeholder="this.t('dashboard.placeholderCaptcha')"
              />
            </el-col>
            <el-col class="vPic" :span="10">
              <img
                v-if="picPath"
                :src="picPath"
                :alt="this.t('dashboard.placeholderCaptcha')"
                @click="loginVerify()"
              >
            </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
<!--            <el-button type="primary" style="width: 46%" @click="checkInit">前往初始化</el-button>-->
            <el-button type="primary" style="width: 100%" @click="submitForm">{{ this.t('dashboard.buttonLogIn') }}</el-button>
          </el-form-item>
        </el-form>
        <el-button type="primary" style="width: 100%" v-if="needInit" @click="jump2init">初始化数据库</el-button>
      </div>
      <div class="login_panle_right" />
      <div class="login_panle_foot">
        <img src="../../assets/beian.png" class="img_icon" />
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32011202000955" rel="noreferrer" target="_blank" style="color:white">苏公网安备32011202000955</a>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { captcha } from '@/api/user'
import { checkDB } from '@/api/initdb'
// import { title } from '@/constant/index'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Login',
  components:{

  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    const checkUsername = (rule, value, callback) => {
      if (value.length < 5) {
        return callback(new Error(this.t('dashboard.validateLoginUsername')))
      } else {
        callback()
      }
    }
    const checkPassword = (rule, value, callback) => {
      if (value.length < 6) {
        return callback(new Error(this.t('dashboard.validateLoginPassword')))
      } else {
        callback()
      }
    }
    return {
      lock: 'lock',
      loginForm: {
        username: 'admin',
        password: '',
        captcha: '',
        captchaId: ''
      },
      rules: {
        username: [{ validator: checkUsername, trigger: 'blur' }],
        password: [{ validator: checkPassword, trigger: 'blur' }],
        captcha: [{ required: true, message: this.t('dashboard.validateLoginCaptcha'), trigger: 'blur' },
          {
            message: this.t('dashboard.validateLoginCaptchaWrongFormat'),
            trigger: 'blur',
          }]
      },
      logVerify: '',
      picPath: '',
      title: this.t('dashboard.title'),
      needInit: false,
    }
  },
  mounted() {
    this.checkInit()
  },
  methods: {
    ...mapActions('user', ['LoginIn']),
    async checkInit() {
      const res = await checkDB()
      if (res.code === 0) {
        if (res.data?.needInit) {
          this.needInit = true
        } else {
          this.loginVerify()
        }
      }
    },
    jump2init() {
      this.$store.commit('user/NeedInit')
      this.$router.push({ name: 'Init' })
    },
    async login() {
      return await this.LoginIn(this.loginForm)
    },
    async submitForm() {
      this.$refs.loginForm.validate(async(v) => {
        if (v) {
          const flag = await this.login()
          if (!flag) {
            this.loginVerify()
          }
        } else {
          this.$message({
            type: 'error',
            message: this.t('dashboard.messageLoginFailure'),
            showClose: true
          })
          this.loginVerify()
          return false
        }
      })
    },
    changeLock() {
      this.lock = this.lock === 'lock' ? 'unlock' : 'lock'
    },
    loginVerify() {
      captcha({}).then((ele) => {
        this.rules.captcha[1].max = ele.data.captchaLength
        this.rules.captcha[1].min = ele.data.captchaLength
        this.picPath = ele.data.picPath
        this.loginForm.captchaId = ele.data.captchaId
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/newLogin.scss";
.img_icon {
  width: 15px;
  height: 15px;
}
</style>
